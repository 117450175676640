import { Dimensions, StyleSheet } from 'react-native'
import {
    horizontalScale,
    moderateScale,
    verticalScale,
} from '../../utils/screenRatio'

export default StyleSheet.create({
    coloumnView: {
        flexDirection: 'row',
    },
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#F8FAFC',
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        paddingBottom: verticalScale(5),
        borderBottomColor: '#CBD5E1',
        borderBottomWidth: 1,
        height: 38,
    },
    headerText: {
        flex: 1,
        color: '#475569',
        fontSize: 14,
        paddingHorizontal: horizontalScale(10),
    },
    titleStyle: {
        color: '#64748B',
        fontSize: 12,
        marginLeft: horizontalScale(10),
    },
    textStyle: {
        borderBottomWidth: 1,
        borderBottomColor: '#CBD5E1',
        height: 42,
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    headerDataContainerStyle: {
        flexDirection: 'row',
    },
    autoWidthCell: {
        flexBasis: 'auto',
        flexGrow: 0,
        flexShrink: 0,
    },
    mobileTableContent: {
        width: '100%',
    },
})
