import React, { FC } from 'react'

import Carousel, { CarouselProps } from '../Carousel/Carousel'
import {
    Dimensions,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
} from 'react-native'
import { Typography, TypographyProps } from '../Typography/Typography'
import getPlatformType from '../../utils/getPlatformType'

export interface CarouselCardProps extends Omit<CarouselProps, 'renderItem'> {
    items: Item[]
    titleProps?: Omit<TypographyProps, 'children'>
    descriptionProps?: Omit<TypographyProps, 'children'>
    textContainerStyle?: StyleProp<ViewStyle>
    cardContainerStyle?: StyleProp<ViewStyle>
}

export interface Item {
    title: string
    description: string
    image?: React.ReactNode
}

export const CarouselCard: FC<CarouselCardProps & {}> = (props) => {
    const {
        items,
        titleProps,
        descriptionProps,
        textContainerStyle,
        cardContainerStyle,
        ...carouselProps
    } = props

    const platformType = getPlatformType()
    const screenWidth = Dimensions.get('screen').width

    return (
        <Carousel
            maxItemsPerView={1}
            paginationType={'arrows'}
            items={items}
            renderItem={(item, index, dotPagination) => {
                return (
                    <View
                        style={[
                            styles.card,
                            {
                                minWidth:
                                    platformType === 'phone'
                                        ? screenWidth
                                        : screenWidth / 6,
                            },
                            cardContainerStyle,
                        ]}
                    >
                        {item.image ? item.image : null}
                        {dotPagination}
                        <View style={[textContainerStyle]}>
                            <Typography
                                variant={'2xl'}
                                style={styles.text}
                                {...titleProps}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                variant={'sm'}
                                style={{ ...styles.text }}
                                {...descriptionProps}
                            >
                                {item.description}
                            </Typography>
                        </View>
                    </View>
                )
            }}
            {...carouselProps}
        />
    )
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: 'white',
        borderRadius: 8,
        alignSelf: 'center',
        height: 'auto',
        alignItems: 'center',
    },
    text: { textAlign: 'center' },
})
