import React, { useCallback } from 'react'
import { FileUploaderWebProps } from './types'

export const FileUploaderWeb = (props: FileUploaderWebProps) => {
    const { testID, inputRef, input } = props

    const loadFileWeb = useCallback(
        (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
            if (e?.target?.files?.length) {
                const url = URL.createObjectURL(e.target.files[0])
                input?.onFileChange?.(url)
            }
        },
        [input],
    )

    return (
        <input
            data-test-id="uploadFile"
            id={`${testID}-file-uploader-web`}
            type="file"
            onChange={loadFileWeb}
            accept={input?.accepts || '*'}
            hidden
            ref={inputRef}
        />
    )
}
