import React, { useState, useCallback, useEffect } from 'react'
import {
    Pressable,
    View,
    Image,
    StyleSheet,
    ViewStyle,
    StyleProp,
} from 'react-native'

import { ReactNativeModal } from 'react-native-modal'

import { Typography } from '../Typography/Typography'
import STRINGS_TESTCASE from '../../utils/stringsTestCase'
import COLORS from '../../utils/colors'
import closeIconPng from '../../assets/images/close.png'

export interface PopoverProps {
    isVisible?: boolean
    isClosable?: boolean
    onPressCloseIcon?: () => void
    containerStyle?: StyleProp<ViewStyle>
    title?: string | React.ReactNode
    titleContainerStyle?: StyleProp<ViewStyle>
    description?: string | React.ReactNode
    children?: React.ReactNode
    closeIcon?: React.ReactNode
}

export const Popover: React.FC<PopoverProps> = (props: PopoverProps) => {
    const {
        isVisible,
        isClosable = true,
        onPressCloseIcon,
        children,
        containerStyle,
        title,
        titleContainerStyle,
        description,
        closeIcon,
    } = props

    const [isModalVisible, setModalVisible] = useState(isVisible || false)
    const shouldShowHeader = title || isClosable

    useEffect(() => {
        setModalVisible(isVisible)
    }, [isVisible])

    {
        /* Needed to make sure there is gap between close icon and text before RN 0.71 */
    }
    const gapView = <View style={styles.gapView}></View>

    const _title =
        typeof title === 'string' ? (
            <>
                <Typography variant="2xl" style={styles.txtTitle}>
                    {title}
                </Typography>
                {gapView}
            </>
        ) : (
            <>
                {title}
                {gapView}
            </>
        )

    const _description =
        typeof description === 'string' ? (
            <Typography variant="lg" style={styles.txtDescription}>
                {description}
            </Typography>
        ) : (
            description
        )

    const _closeIcon = closeIcon ? (
        closeIcon
    ) : (
        <Image source={closeIconPng} style={styles.crossIcon} />
    )

    const _onPressCloseIcon = useCallback(() => {
        if (onPressCloseIcon) {
            onPressCloseIcon()
        } else {
            setModalVisible(!isModalVisible)
        }
    }, [onPressCloseIcon, isModalVisible, setModalVisible])

    return (
        <ReactNativeModal
            isVisible={isModalVisible}
            style={styles.modal}
            onBackdropPress={isClosable ? _onPressCloseIcon : null}
        >
            <View style={[styles.innerContainer, containerStyle]}>
                {shouldShowHeader && (
                    <View style={[styles.titleContainer, titleContainerStyle]}>
                        {title ? _title : null}
                        {isClosable && (
                            <Pressable
                                onPress={_onPressCloseIcon}
                                testID={
                                    STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON
                                }
                                style={closeIcon ? null : styles.iconContainer}
                            >
                                {_closeIcon}
                            </Pressable>
                        )}
                    </View>
                )}
                {description ? _description : null}
                {children ? children : null}
            </View>
        </ReactNativeModal>
    )
}

const styles = StyleSheet.create({
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainContainer: {
        flex: 1,
        borderTopRightRadius: 24,
    },
    innerContainer: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: COLORS.COLOR_WHITE,
        paddingHorizontal: 30,
        paddingVertical: 25,
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        borderBottomColor: COLORS.COLOR_GREY,
        borderBottomWidth: 1,
        borderStyle: 'solid',
    },
    gapView: {
        width: 12,
    },
    horizontalLine: {
        height: 1,
        backgroundColor: COLORS.COLOR_GREY,
        marginTop: 15,
        marginHorizontal: -30,
    },
    iconContainer: {
        alignSelf: 'flex-start',
        marginTop: 8,
    },
    crossIcon: {
        width: 14,
        height: 14,
    },
    txtTitle: {
        color: COLORS.COLOR_LIGHTBLACK,
        fontWeight: '700',
        flexShrink: 1,
        marginTop: 0,
        marginBottom: 0,
    },
    txtDescription: {
        marginTop: 15,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    txtFooter: {
        fontSize: 16,
        marginTop: 40,
        color: COLORS.COLOR_MEDIUMBLUE,
        alignSelf: 'center',
        fontWeight: '700',
        marginHorizontal: 15,
    },
})
